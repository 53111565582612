import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";
import { StyledIcon } from "../Icon/style";
import { StyledFormSelect } from "../../commons/Form";
import { StyledLoader } from "../Loader/style";

export const StyledPaymentDetail = styled.div`
  @media (${BP.ipad}) {
    padding-top: ${Rem(36)};
  }
`;

export const StyledPaymentDetailContent = styled.div`
  padding-top: ${Rem(36)};
  padding-bottom: ${Rem(0)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    display: block;
    overflow: hidden;
    position: relative;
    margin-bottom: ${Rem(40)};
  }
`;

export const StyledPaymentDetailBigTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${Rem(26)};
  line-height: ${Rem(36)};
  letter-spacing: ${Rem(5)};
  margin-bottom: ${Rem(24)};
  text-align: center;
  text-transform: uppercase;

  @media (${BP.ipad}) {
    font-size: ${Rem(36)};
    line-height: ${Rem(36)};
    margin-bottom: ${Rem(42)};
  }
`;

export const StyledPaymentDetailChoosersHiveFilterCheckbox = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: ${Rem(32)};
  margin-bottom: ${Rem(16)};
  cursor: pointer;
  width: 100%;

  @media (${BP.ipad}) {
    width: auto;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: ${Rem(18)};
    height: ${Rem(18)};
    border-radius: ${Rem(3)};
    margin-right: ${Rem(8)};
    border: 1px solid ${Colors.yellow};

    @media (${BP.ipad}) {
      width: ${Rem(28)};
      height: ${Rem(28)};
    }
  }

  ${StyledIcon} {
    position: absolute;
    width: ${Rem(10)};
    top: 50%;
    left: ${Rem(3)};
    transform: translateY(-50%);

    @media (${BP.ipad}) {
      width: ${Rem(20)};
      left: ${Rem(6)};
    }
  }
`;

export const StyledPaymentDetailChooserItemFilterInput = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: ${Rem(10)};
  padding: ${Rem(10)};
  font-size: ${Rem(16)};

  @media (${BP.ipad}) {
    padding: ${Rem(16)};
    font-size: ${Rem(18)};
  }

  ${StyledFormSelect} {
    > select {
      border: none;
      padding: 0 ${Rem(30)} 0 0;
      height: auto;
      letter-spacing: normal;
      font-size: ${Rem(16)};
      font-weight: bold;
      text-transform: none;
      text-overflow: ellipsis;
      background-color: ${Colors.white};

      @media (${BP.ipad}) {
        font-size: ${Rem(18)};
      }
    }

    &:after {
      right: 0;
      background-image: url('/2020/svg/icons/chevron-down-yellow.svg');
    }
  }
`;

export const StyledPaymentDetailChooserBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  & > div {
    justify-content: center;
  }

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
    width: 400px;
  }
`;

export const StyledPaymentDetailChooserCTA = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${Rem(30)} 0;
  height: ${Rem(66)};
`;

export const StyledPaymentDetailChooserCTAButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Rem(66)};
  color: ${Colors.black};
  background-color: ${Colors.yellow};
  cursor: pointer;
  font-size: ${Rem(20)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(0.5)};
  padding: 0 ${Rem(40)};

  @media (${BP.ipad}) {
    width: auto;
  }

  ${StyledIcon} {
    margin-left: ${Rem(16)};
  }

  ${(props) =>
    props.isDisabled
    && css`
      filter: grayscale(1);
      pointer-events: none;
      cursor: default;
    `}

  ${(props) =>
    props.isFloat
    && css`
      z-index: 9;
      position: fixed;
      bottom: 0px;

      @media (${BP.ipad}) {
        right: 10px;
      }
    `}
`;

export const StyledPaymentDetailChooserCTAButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    flex-direction: row;
  }

  > ${StyledPaymentDetailChooserCTAButton} {
    margin-bottom: ${Rem(30)};
  }
`;

export const StyledPaymentDetailSummaryEditorial = styled.div`
  width: 100%;
`;

export const StyledPaymentDetailSummaryEditorialPayments = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${Rem(40)};

  @media (${BP.ipad}) {
    flex-direction: row;
  }

  > img {
    max-height: ${Rem(90)};
    margin: 0 ${Rem(16)} ${Rem(16)};

    @media (${BP.ipad}) {
      margin: 0 ${Rem(16)};
    }

    &:last-child {
      max-height: ${Rem(60)};
      margin-bottom: 0;
    }
  }
`;

export const StyledPaymentDetailSummaryEditorialForm = styled.div`
  position: relative;
  padding: ${Rem(32)} ${Rem(16)};
  min-height: ${Rem(260)};

  @media (${BP.ipad}) {
    padding: ${Rem(48)} ${Rem(32)};
  }

  & > ${StyledLoader} {
    position: absolute;
    z-index: 9;
  }
`;

export const StyledPaymentDetailSummaryEditorialFormInputWithError = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${Rem(8)};

  @media (${BP.ipad}) {
    margin-right: ${Rem(24)};
  }

  width: 100%;
  max-width: ${Rem(688)};

  ${(props) =>
    props.isSmall
    && css`
      max-width: ${Rem(134)};
    `}

  ${(props) =>
    props.isMid
    && css`
      max-width: ${Rem(261)};
    `}

  > span {
    margin-bottom: ${Rem(8)};
  }
`;

export const StyledPaymentDetailSummaryEditorialFormBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${Rem(24)};

  > span {
    display: block;
    width: 100%;
    padding-bottom: ${Rem(8)};
    font-size: ${Rem(11)};

    @media (${BP.ipad}) {
      font-size: ${Rem(14)};
    }
  }

  > input, > ${StyledPaymentDetailSummaryEditorialFormInputWithError} input {
    appearance: none;
    display: block;
    font-family: inherit;
    font-size: ${Rem(16)};
    padding: ${Rem(8)} ${Rem(12)};
    width: 100%;
    margin-right: ${Rem(8)};
    text-overflow: ellipsis;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    @media (${BP.ipad}) {
      font-size: ${Rem(20)};
      padding: ${Rem(12)} ${Rem(16)};
      margin-right: ${Rem(24)};
    }
  }

  > ${StyledPaymentDetailSummaryEditorialFormInputWithError} ${StyledPaymentDetailChooserItemFilterInput} {
    max-width: fit-content;
    border: 3px solid ${Colors.yellow};
    border-radius: 0;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  }
`;

export const StyledPaymentDetailSummaryEditorialFormInput = styled.input`
  appearance: none;
  display: block;
  font-family: inherit;
  font-size: ${Rem(14)};
  letter-spacing: ${Rem(0.5)};
  border: 3px solid ${Colors.yellow};
  padding: ${Rem(5)} ${Rem(16)};
  width: 100%;
  max-width: ${Rem(688)};
  margin-right: ${Rem(8)};
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    margin-right: ${Rem(24)};
  }

  ${(props) =>
    props.isSmall
    && css`
      max-width: ${Rem(134)};
    `}

  ${(props) =>
    props.isMid
    && css`
      max-width: ${Rem(261)};
    `}
  
  ${(props) =>
    props.as === "select"
    && css`
      max-width: ${Rem(177)};
    `}
`;

export const StyledPaymentDetailSummaryEditorialFormAnchor = styled.a`
  color: black;
  text-decoration: underline;
`;

export const StyledPaymentDetailTooltip = styled.div`
  display: inline-block;
  position: relative;
  margin-left: ${Rem(8)};
`;

export const StyledPaymentDetailTooltipTrigger = styled.div`
  width: ${Rem(18)};
  height: ${Rem(18)};
  top: ${Rem(16)};
  right: ${Rem(16)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  border-radius: 50%;
  font-size: ${Rem(14)};
  z-index: 4;
  cursor: pointer;
`;

export const StyledPaymentDetailAddressTitle = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${Rem(688)};
  align-items: center;
  margin-bottom: ${Rem(8)};
  flex-wrap: wrap;

  & > div:first-child {
    margin-bottom: ${Rem(8)};
  }

  & > ${StyledPaymentDetailChoosersHiveFilterCheckbox} {
    margin-right: 0;
  }

  @media (${BP.ipad}) {
    flex-wrap: nowrap;

    & > div:first-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledDivider = styled.div`
  margin-left: ${Rem(15)};
  margin-right: ${Rem(15)};
  margin-bottom: ${Rem(40)};
  width: calc(100% - ${Rem(15)} - ${Rem(15)}); 
  height: ${Rem(2)};
  border: 0;
  background: ${Colors.yellow};
  position: relative;
`;
