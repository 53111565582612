import React, { useState, createContext } from "react";

export const PaymentContext = createContext();

export const PaymentContextProvider = (props) => {

  // CONSTANTS
  const emailRegex = /\S+@\S+\.\S+/;
  // STATES
  const [userInfo, setUserInfo] = useState({ email: "" });
  const [extraVisible, setExtraVisible] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [userExist, setUserExist] = useState(false);
  const [accessToken, setAccessToken] = useState( null);
  const [isLoading, setIsLoading] = useState(false);
  // FUNCTIONS
  const loadAccessToken = () => {
    try {
      const _accessToken = document.cookie.split(";").find(el=> el.split("=")[0].trim() === "acstk").split("=")[1].trim();
      if (_accessToken) {
        setAccessToken(_accessToken);
        setShowForm(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const setUserAuthToken = (data) => {
    var d = new Date();
    d.setTime(d.getTime() + (60 * 40 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = "acstk=" + data.access + ";" + expires + ";path=/";
    d = new Date();
    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
    var expires2 = "expires=" + d.toUTCString();
    document.cookie = "rfstk=" + data.refresh + ";" + expires2 + ";path=/";
  };

  const deleteUserAuthToken = () => {
    document.cookie = "acstk=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    document.cookie = "rfstk=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
  };
  
  const resetUserSession = () => {
    setShowForm(true);
    deleteUserAuthToken();
  };

  return (
    <PaymentContext.Provider value={{
      labels: props.labels,
      parentUrl: props.parentUrl,
      extraVisible,
      setExtraVisible,
      showForm,
      setShowForm,
      userExist,
      setUserExist,
      accessToken,
      setAccessToken,
      isLoading,
      setIsLoading,
      setUserAuthToken,
      deleteUserAuthToken,
      resetUserSession,
      loadAccessToken,
      userInfo,
      setUserInfo,
      emailRegex,
    }}>
      {props.children}
    </PaymentContext.Provider>
  );
};
