import React, { useState, useContext, useRef } from "react";

import { Colors } from "../../commons/Theme";
import Icon, { icons } from "../Icon/Icon";
import {
  StyledPaymentDetailBigTitle,
  StyledPaymentDetailChooserCTAButtons,
  StyledPaymentDetailChooserCTAButton,
  StyledPaymentDetailSummaryEditorial,
  StyledPaymentDetailSummaryEditorialForm,
  StyledPaymentDetailSummaryEditorialFormBlock,
  StyledPaymentDetailSummaryEditorialFormInput,
  StyledPaymentDetailSummaryEditorialFormAnchor,
} from "./style";

import { PaymentContext } from "./PaymentContext";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { DataContext } from "../../contexts/DataContext";

export default ({ validation }) => {

  // CONTEXTS
  const {
    labels,
    setShowForm,
    setIsLoading,
    setAccessToken,
    setUserAuthToken,
    userInfo,
  } = useContext(PaymentContext);

  const {
    pageLabels,
  } = useContext(DataContext);

  const formRef = useRef(null);
  // STATES
  const [loginData, setLoginData] = useState({
    email: userInfo.email || "",
    password: "",
  });
  const [loginError, setLoginError] = useState({
    email: null,
    password: null,
  });
  const { loginUser } = useApi();
  // FUNCTIONS
  const getErrorMessage = (errorInfo) => {
    const errorKey = errorInfo.error;
    const translationKey = `ADOPTION_FORM_${errorKey}`;

    let errorMessage;
    if (translationKey in labels) {
      errorMessage = labels[translationKey];
    } else {
      errorMessage = errorInfo.message;
    }

    if (errorKey === "ADDRESS_NOT_VERIFIED") {
      return `${labels.ADOPTION_FORM_ADDRESS_SUGGESTION} ${errorMessage}`;
    }
    return errorMessage;
  };
  const performLogin = () => {
    if (validation && validation()) {
      return;
    }

    if(formRef.current && !formRef.current.reportValidity()) {
      return;
    }

    toast.configure();
    setIsLoading(true);
    loginUser(loginData).then((res) => {
      setIsLoading(false);
      if (res && res.error) {
        // LOGIN FAILED
        toast.error("Errore, non è stato possibile autenticare l'utente", {
          containerId: "Alert",
          position: toast.POSITION.TOP_CENTER, 
        });
        const errorMessage = res.message;

        let newLoginError = {};
        if (errorMessage.error === "VALIDATION_ERROR" && errorMessage.details) {
          for (const [key, value] of Object.entries(errorMessage.details)) {
            newLoginError[key] = value;
          }
        }
        setLoginError(newLoginError);
      } else {
        // LOGIN SUCCESS
        toast.success("Login effettuato con successo!", {
          containerId: "Alert",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, 
        });
        setUserAuthToken(res.data.data);
        setAccessToken(res.data.data.access);
        setShowForm(false);
      }
    });
  };

  // RENDERING
  return (
    <>
      <StyledPaymentDetailSummaryEditorial>
        <StyledPaymentDetailSummaryEditorialForm Validate>
          <StyledPaymentDetailBigTitle>
            Effettua il login
          </StyledPaymentDetailBigTitle>
          <form ref={formRef}>
            <StyledPaymentDetailSummaryEditorialFormBlock>
              <StyledPaymentDetailSummaryEditorialFormInput
                type="email"
                placeholder={pageLabels.email}
                required
                value={loginData.email}
                onChange={(e) => {
                  setLoginData({
                    ...loginData,
                    email: e.currentTarget.value, 
                  });
                }}
              />
              {loginError.email && loginError.email.map(
                (error, index) => (
                  <span key={index} style={{ color: "red" }}>
                    {getErrorMessage(error)}
                  </span>))
              }
            </StyledPaymentDetailSummaryEditorialFormBlock>
            <StyledPaymentDetailSummaryEditorialFormBlock>
              <StyledPaymentDetailSummaryEditorialFormInput
                type="password"
                placeholder={pageLabels.password}
                autoComplete="on"
                required
                value={loginData.password}
                onChange={(e) => {
                  setLoginData({
                    ...loginData,
                    password: e.currentTarget.value, 
                  });
                }}
              />
              {loginError.password && loginError.password.map(
                (error, index) => (
                  <span key={index} style={{ color: "red" }}>
                    {getErrorMessage(error)}
                  </span>))
              }
            </StyledPaymentDetailSummaryEditorialFormBlock>
            <p>
              <StyledPaymentDetailSummaryEditorialFormAnchor 
                href='https://app.3bee.com' 
                target='_blank'>
                {pageLabels.forgotPassword}
              </StyledPaymentDetailSummaryEditorialFormAnchor>
            </p>
            <br/>
           
          </form>
        </StyledPaymentDetailSummaryEditorialForm>
      </StyledPaymentDetailSummaryEditorial>
      <StyledPaymentDetailChooserCTAButtons>
        <StyledPaymentDetailChooserCTAButton
          onClick={performLogin}
        >
          {pageLabels.continue}{" "}
          <Icon icon={icons.arrowRight} color={Colors.black} />
        </StyledPaymentDetailChooserCTAButton>
      </StyledPaymentDetailChooserCTAButtons>
    </>
  );
};

/*
<p>
<StyledPaymentDetailSummaryEditorialFormAnchor 
  onClick={() => setUserExist(false)}
  >
  Non hai un account? Registrati!
</StyledPaymentDetailSummaryEditorialFormAnchor>
</p>*/
